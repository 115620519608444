footer {}

.footer {
	padding-top: 60px;
	.inside_cont {
		display: flex;
		flex-direction: column;
		align-items: center;
		.logo {
			img {
				max-width: 262px;
			}
		}
		.bottom_area {
			width: 100%;
			margin-top: 40px;
			border-top: 1px solid #fff;
			padding: 20px 0;
			display: flex;
			justify-content: center;
			align-items: center;
			a {
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
}



// MEDIA QUERIES

@media screen and (max-width: 1440px) {
	.footer {
		padding-top: 20px;
	}
}