.main-header {}

.header-desktop {
	@include respond-to('medium') {
		display: none;
	}
}

.header-mobile {
	display: none;
	height: 70px;
	background-color: #fff;
	box-shadow: 0 0 15px 0 rgba($color: #000000, $alpha: 0.1);
	@include respond-to('medium') {
		display: block;
	}
	& .mobile-menu-btn {
		float: $left;
	}
	&__logo {
		margin-#{$right}: 15px;
		margin-top: 7px;
		float: $right;
		@include respond-to('extra-small') {
			margin-top: 13px;
			margin-#{$right}: 5px;
		}
		@include respond-to('mobile-l') {
			margin-top: 17px;
		}
		& > img {
			max-width: 300px;
			@include respond-to('extra-small') {
				max-width: 250px;
			}
			@include respond-to('mobile-l') {
				max-width: 200px;
			}
		}
	}
	&__tel {
		display: inline-block;
		margin-top: 17px;
		i {
			color: $accent;
			font-size: 32px;
		}
	}
}

header {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 5;
	padding-top: 13px;
	padding-bottom: 13px;
	transition: .3s;
	@include respond-to('medium') {
		padding: 0;
	}
	.back {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		z-index: 1;
	}
	.container {
		z-index: 2;
		position: relative;
	}
	&.active {
		background: none;
		background-color: #fff;
		.back {
			opacity: 0;
			visibility: hidden;
		}
	}
	.inside_cont {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

nav {
	width: 100%;
	max-width: 867px;
	.main_list {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		.main_li > a {
			color: #373737;
			font-size: 18px;
			&.active,
			&:hover,
			&:focus {
				color: #023e83;
				font-weight: 700;
				text-decoration: none;
			}
		}
		.main_li {
			position: relative;
		}
		.hidden_area {
			position: absolute;
			width: 210px;
			box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
			background-color: #ffffff;
			padding: 10px 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			top: 30px;
			left: 50%;
			transform: translateX(-50%);
			z-index: 5;
			opacity: 0;
			visibility: hidden;
			transition: .3s;
			&.active {
				opacity: 1;
				visibility: visible;
			}
			.dropped_list {
				width: 100%;
				max-width: 160px;
				display: flex;
				flex-direction: column;
				align-items: center;
				position: relative;
				&:before {
					content: "";
					position: absolute;
					top: -20px;
					left: 50%;
					transform: translateX(-50%);
					width: 36px;
					height: 10px;
					background: url('../images/triangle.png') top center no-repeat;
					background-size: contain;
				}
				li {
					width: 100%;
					display: flex;
					&:last-child {
						a {
							border-bottom: none !important;
						}
					}
					a {
						width: 100%;
						color: #043c87;
						font-size: 16px;
						font-weight: 700;
						text-align: left;
						padding: 20px 0;
						border-bottom: 1px solid #dedede;
						&:hover {
							text-decoration: none;
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 1200px) {
	nav {
		.main_list .main_li {
			&:hover {
				.hidden_area {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
}

// .mob_menu__button {
// 	width: 24px;
// 	background: transparent;
// 	border: none;
// 	padding: 0;
// 	margin: 0;
// 	display: none;
// }
// .mob_menu__button span {
// 	width: 100%;
// 	float: left;
// 	display: block;
// 	height: 2px;
// 	border-radius: 1px;
// 	background-color: #000;
// 	position: relative;
// 	transition: .2s;
// }
// .mob_menu__button span:not(:last-child) {
// 	margin-bottom: 5px;
// }


@media screen and (max-width: 1300px) {
	header {
		.logo img {
			max-width: 250px;
		}
	}
	nav .main_list .main_li > a {
		font-size: 16px;
	}
	nav {
		max-width: 715px;
	}
}

@media screen and (max-width: 1199px) {
	nav {
		max-width: 615px;
	}
	nav .main_list .main_li > a {
		font-size: 14px;
	}
}

@media screen and (max-width: 991px) {
	.mob_menu__button {
		display: block;
	}
	header {
		nav {
			display: none;
		}
	}
	nav .main_list .main_li > a {
		font-size: 16px;
	}
	nav .main_list .hidden_area .dropped_list li a {
		font-size: 13px;
		padding: 10px 0;
	}
	nav .main_list .hidden_area .dropped_list li {
		margin-bottom: 0;
	}
	header .back {
		height: 130px;
	}
}