.banner-top-content {
	position: relative;
	width: 100%;
	min-height: 450px;
	@include respond-to('small') {
		min-height: inherit;
	}
	&__image {
		background-position: center center;
		background-size: cover;
		height: 450px;
		// box-shadow: inset 0px -10px 20px 0px rgba(0, 0, 0, 0.15);
	}
	&__shadow {
		content: url('../images/banner-shadow.png');
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
	}
	&__title {
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		z-index: 2;
		max-width: 980px;
		width: 100%;
		margin: 0 auto;
		text-align: $right;
		& > h1 {
			font-weight: 900;
			text-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
			@include respond-to('medium') {
				padding-#{$right}: 15px;
			}
		}
	}
}
