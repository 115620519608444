.main_block {
	width: 100%;
	.slider_main_block {
		width: 100%;
		.slide {
			position: relative;
			.container {
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				z-index: 2;
				padding-top: 435px;
				padding-bottom: 400px;
			}
		}
		.slide_img {
			width: 100%;
			object-fit: cover;
			height: 1111px;
		}
	}
	.inside_cont {
		display: flex;
		justify-content: flex-end;
		& h1 {
			max-width: 520px;
			text-shadow: 0 0 15px #000;
		}
	}
}

.banner_scroll_body {
	width: 100%;
	height: 850px;
	overflow: hidden;
	#banners-scroll {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
	}
}
