.wrapper-all-content {
	position: relative;
	width: 100%;
	&_pt {
		padding-top: 50px;
		@include respond-to('medium') {
			padding-top: 0;
		}
	}
}

html {
	background-color: #fff;
	// font-family: Assistant, sans-serif;
	// font-family: 'Cinzel', serif;
	font-weight: normal;
}

.container {
	width: 1430px;
}

body.active {
	overflow: hidden;
}

.wrapper {
	overflow: hidden;
}

h1 {
	color: #ffffff;
	font-size: 40px;
	font-weight: 700;
	//text-transform: uppercase;
} 
.warp_homepage_form.page_width h2{
	font-size: 25px;
    color: #084999;
    font-weight: 600;
}
.btn-main {
    background: #063b7e;
	color: #fff;
}
.btn-main:hover {
    background: #0656bb;
	color: #fff;
}
.warp_homepage_form.page_width{
	margin-top: 10px;
}
.form-homepage{
	margin: 25px 0;
    padding-bottom: 25px;
    border-bottom: 1px solid #f2eef0;
}
.main_back_area {
	// background: url('../images/background.png') top center no-repeat;
	background-image: url('../images/bg.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top center;
}

.about_section {
	width: 100%;
	position: relative;
	z-index: 3;
	@include respond-to('medium') {
		padding-top: 30px !important;
	}
	&.about_section2 {
		// padding-top: 40px;
	}
	.top_back {
		position: absolute;
		top: -233px;
		left: 0;
		right: 0;
		width: 100%;
		z-index: 2;
		height: 233px;
		background-position: center bottom;
		background-repeat: no-repeat;
		background-size: cover;
	}
	.about_section_cont .inside_cont {
		position: relative;
		padding-bottom: 212px;
		.left_side {
			width: 100%;
			max-width: 691px;
			@include respond-to('medium') {
				max-width: 100%;
			}
			.logo_img {
				margin-bottom: 50px;
			}
			p {
				width: 100%;
				color: #373737;
				font-size: 18px;
				line-height: 25px;
				margin-bottom: 28px;
				&:last-of-type {
					margin-bottom: 0;
				}
			}
			i {
				margin-top: 50px;
				width: 60px;
				height: 2px;
				background-color: #033a80;
				display: inline-block;
			}
		}
		.right_block {
			position: absolute;
			right: -150px;
			top: -208px;
			z-index: 3;
		}
	}
}

.investment_wrap {
	width: 100%;
	background: url('../images/back4.png') top center no-repeat;
	background-size: auto;
	height: 500px;
	padding-top: 75px;
	@include respond-to('medium') {
		background: url('../images/back4_a.png') top center no-repeat;
	}
	.inside_cont {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	h3 {
		margin-bottom: 50px;
	}
	p {
		width: 100%;
		text-align: center;
		color: #ffffff;
		font-size: 18px;
		line-height: 25px;
		max-width: 1152px;
		margin-bottom: 50px;
	}
	i {
		width: 60px;
		height: 2px;
		background-color: #ffffff;
	}
}

h3 {
	width: 100%;
	text-align: center;
	color: #ffffff;
	font-size: 25px;
	font-weight: 700;
	//text-transform: uppercase;
}

.about_us {
	width: 100%;
	.inside_cont {
		display: flex;
		position: relative;
		justify-content: flex-end;
		.text_cont {
			width: 100%;
			max-width: 711px;
			padding-top: 140px;
			padding-bottom: 257px;
			h3 {
				color: #063b7e;
				text-align: left;
				margin-bottom: 50px;
			}
			p {
				width: 100%;
				color: #373737;
				font-size: 18px;
				line-height: 25px;
			}
			i {
				width: 60px;
				height: 2px;
				background-color: #033a80;
				display: inline-block;
				margin-top: 46px;
			}
		}
		.left_img {
			position: absolute;
			left: -50px;
			top: 0;
		}
	}
}

.contact_wrapper {
	width: 100%;
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.05);
	background-color: #ffffff;
	position: relative;
	overflow: hidden;
	z-index: 4;
	.back_img {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		width: calc(100% - 814px);
		height: 100%;
	}
	.inside_cont {
		display: flex;
		justify-content: flex-start;
		padding-top: 100px;
		padding-bottom: 100px;
		.left_side {
			width: 100%;
			max-width: 515px;
			h3 {
				text-align: left;
				color: #084999;
				font-size: 25px;
				margin-bottom: 10px;
			}
			p {
				color: #373737;
				font-size: 20px;
				margin-bottom: 35px;
			}
			form {
				width: 100%;
				max-width: 496px;
				input {
					width: 100%;
					height: 60px;
					margin-bottom: 20px;
					border: none;
					border-bottom: 1px solid #c0c0c0;
					color: #063b7e;
					font-size: 18px;
					//text-transform: uppercase;
					letter-spacing: -0.36px;
					&::placeholder {
						color: #063b7e; 
						font-size: 18px;
						//text-transform: uppercase;
						letter-spacing: -0.36px;
					}
				}
				button {
					width: 100%;
					margin-top: 40px;
					display: flex;
					justify-content: center;
					align-items: center;
					color: #ffffff;
					font-size: 18px;
					font-weight: 700;
					//text-transform: uppercase;
					height: 50px;
					background-color: #063b7e;
					border: none;
					transition: .3s;
					&:hover {
						background-color: #195eb5;
					}
				}
			}
		}
	}
}

// .responsive_wrapper {
// 	width: 100%;
// 	position: fixed;
// 	top: 0;
// 	left: 0;
// 	right: 0;
// 	bottom: 0;
// 	height: 100vh;
// 	z-index: 100;
// 	transition: .3s;
// 	transform: translateX(110%);
// 	background: #fff;
// 	overflow-y: auto;
// 	.inner {
// 		width: 100%;
// 		position: relative;
// 		padding: 50px 30px;
// 		padding-bottom: 80px;
// 		display: flex;
// 		flex-direction: column;
// 		align-items: center;
// 		.close_menu {
// 			position: absolute;
// 			top: 10px;
// 			right: 20px;
// 			color: #000;
// 			svg {
// 				width: 15px;
// 			}
// 		}
// 		nav {
// 			ul {
// 				display: flex;
// 				flex-direction: column;
// 				align-items: center;
// 				li {
// 					margin-bottom: 40px;
// 					&:last-child {
// 						margin-bottom: 0;
// 					}
// 				}
// 			}
// 		}
// 	}
// }

// .responsive_wrapper.active {
// 	transform: none;
// }

.page_main_section {
	width: 100%;
	background: url('../images/back6.png') top center no-repeat;
	background-size: cover;
	padding-top: 216px;
	padding-bottom: 195px;
	h1 {
		text-align: right;
	}
}

.page_content_wrap {
	width: 100%;
	position: relative;
	z-index: 3;
	.left_img {
		position: absolute;
		z-index: 2;
		// top: -150px;
		top: -203px;
		left: 0;
		bottom: 0;
		height: 1609px;
		width: 100%;
	}
	.container {
		position: relative;
		z-index: 3;
	}
	.inside_cont {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		.left_side {
			width: 100%;
			max-width: 300px;
			@include respond-to('medium') {
				max-width: 100%;
			}
			.title {
				width: 100%;
				color: #043c80;
				font-size: 25px;
				font-weight: 800;
				padding-bottom: 22px;
				border-bottom: 1px solid #043c80;
				margin-bottom: 10px;
			}
			ul {
				width: 100%;
				@include respond-to('medium') {
					// text-align: center;
					display: flex;
					justify-content: flex-start;
					flex-flow: row wrap;
				}
				li {
					width: 100%;
					@include respond-to('medium') {
						display: inline-block;
						// margin-#{$left}: 20px;
						width: 100% !important;
						// min-width: ;
						padding: 0 10px;
					}
					&:first-child {
						margin-#{$left}: 0;
					}
					a {
						color: #525253;
						font-size: 20px;
						font-weight: 400;
						//text-transform: uppercase; 
						padding: 25px 0;
						width: 100%;
						font-family: Assistant;
						border-bottom: 1px solid #d9d9d9;
						display: inline-block;
						&.active,
						&:hover,
						&:focus {
							color: #043c80;
							text-decoration: none;
							border-bottom: 1px solid #043c80;
						}
					}
					
				}
				li.main_li.iner-li {
					a {
						padding-left: 13px !important;
					}
				}
			}
		}
		.right_side {
			width: 100%;
			max-width: 960px;
			display: flex;
			flex-direction: column;
			align-items: $left;
			padding-bottom: 40px;
			@include respond-to('medium') {
				padding-bottom: 20px;
			}
			p {
				width: 100%;
				color: #565656;
				font-size: 18px;
				font-weight: 400;
				// margin-bottom: 22px;
				line-height: 23px;
				@include respond-to('small') {
					font-size: 16px;
					line-height: 1.4;
				}
				b {
					color: #043c80;
					font-weight: 800;
				}
			}
			.img1 {
				width: 100%;
				max-width: 871px;
				margin-bottom: 30px;
			}
		}
	}
}

.disclamer-footer{
	margin-top: 15px;
}