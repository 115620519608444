.foundation_frm .form-control{
	@include respond-to('medium') {
		margin: 5px 0;
	}
}
.found {
    margin: 10px 0;
    padding: 10px 20px;
    transition: all 0.25s;
    border-left: 3px solid greenyellow;
 
}
.pf{
    margin-top: -120px;
    padding-bottom: 120px;
    display: block;
    height: 0;
    @include respond-to('medium') {
		margin-top: -85px;
        padding-bottom: 85px;
	}
 
}
.found:hover {
    background: #f7f7f7;
}
.found.unauthorized {
    background: #eee;
    border-left: 3px solid red;
    cursor: not-allowed;
}

h2.foundation_cat_header {
    margin-top: 35px;
    margin-bottom: 20px; 
    font-weight: 600;
} 
a.foundation_title {
    // border-bottom: 1px solid;
}
.warp-form-s{
    min-height: 55px;
}
@media screen and (min-width: 1300px) {
    .table-r{
        max-width: 1000px;
    }
    .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th{
        padding: 5px;
    }
}
li.child-li {
    padding-left: 12%;
}
// tr:nth-child(2) {
//     background: #e8e8e8;
//     font-weight: 600;
//     font-size: 16px;
//     color: #000;
//   }

  .right_side h3{
      text-align: left;
      margin-bottom: 7px;
  }
  .right_side.perf .foundation_cat_header{
      display: none;
  }

  li.main_li.iner-li{
    padding-left: 20px;
  }
  li.main_li.iner-li a{
    font-size: 18px !important;
    padding: 10px 0 !important; 
  } 
  li.main_li.iner-li a{
    font-size: 15px !important;
    padding: 5px 0 !important; 
    line-height: 1.2;
  } 
//   .right_side.perf .foundation_cat_header:nth-child(1){
//     display: block !important;
// } 
.right_side .table-hover tr:nth-child(odd) {
    background: #fafafa;
}
td.firstc {
    min-width: 183px;
} 
// .table-responsive>.fixed-column {
//     position: absolute;
//     display: inline-block;
//     width: auto;
//     border-right: 1px solid #ddd;
// }
 
// @media(min-width:768px) {
//     .table-responsive>.fixed-column {
//         display: none;
//     }
// }

select option:disabled {
    color: rgb(250, 14, 14);
    cursor: not-allowed;
}

.content_text .table-hover td, .content_text .table-hover th {
    padding: 12px 12px;
    border-top: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
} 
.content_text .table-hover tr:nth-child(odd) {
    background: #fafafa;
}

.sticky-col {
    position: -webkit-sticky;
    position: sticky;
    width: 100px !important;
    // min-width: 130px !important;
    background-color: #fafafa;
    left: 0;
} 
.table_foundation_th {
    position: -webkit-sticky; 
    position: sticky; 
    text-transform: capitalize; 
    width: 105px !important;
    min-width: 70px !important;
    background-color: #fafafa;
    left: 0;
} 
.table-hover tbody tr td:first-child{
    position: -webkit-sticky;
    position: sticky;
    width: 105px !important;
    min-width: 90px !important;
    background-color: #fafafa;
    left: 0;
    @include respond-to('medium') {
        left: -21px;
    }
} 