.wrap-content {
	padding: 20px 0;
}

.warp_link_to_historical{
	padding: 1% 0 1% 0;
} 
.warp_foundationauthorized{
	padding: 3% 0 3% 0; 
}
.table-r{
	max-width: 700px;
	@include respond-to('medium') {
		max-width: 550px; 
	}

	@include respond-to('extra-small') {
		max-width: 360px;
	}
}
.right_side .content{
	padding: 15px 20px;
	overflow-x: auto;
}
.title-main {
	font-size: 40px;
	font-weight: 300;
	line-height: 1;
	color: $accent;
	margin: 15px 0 30px;
}

.wrap-all-content-not-banner,
.padding-top-catalog-item {
	margin-top: 180px;
	@include respond-to('medium') {
		margin-top: 80px;
	}
}

.hr-content {
	border-top: 1px solid #d9d9d9;
	margin: 0 0 20px;
}

.content-text {
	h2,
	h3 {
		font-size: 18px;
		font-weight: 700;
		line-height: 1.2;
		color: $accent;
		margin: 20px 0 20px;
		@include respond-to('large') {
			font-size: 26px;
		}
		@include respond-to('extra-small') {
			font-size: 20px;
		}
	}
	p {
		font-size: 18px;
		font-weight: 400;
		color: #565656;
		line-height: 1.2;
		@include respond-to('extra-small') {
			font-size: 16px;
			text-align: justify;
		}
	}
	iframe {
		@include respond-to('small') {
			text-align: center !important;
			margin: 15px auto !important;
			width: 100% !important;
			height: auto !important;
			float: none !important;
			display: block !important;
		}
	}
	img {
		@include respond-to('small') {
			float: none !important;
			text-align: center !important;
			width: auto !important;
			height: auto !important;
			margin: 15px auto !important;
		}
	}
}