.warp_map_text {
    border-left: 1px solid #dbdbdb;
    padding: 0px 30px;
    width: 620px;
    width: calc(100% - 400px);
} 
.warp_popup_form {
    padding: 0px 40px;
    width: 400px;
}

.modal .hide_on_modal{
    display: none;
} 
@media (min-width: 1020px){
    .modal-lg{
        width: 1020px;
    }
    .modal-lg .modal-content{
        padding: 20px 10px;
    }
}
@media (min-width: 767px){
    .warp_popup_form{
        padding: 0px 40px;
        width: 400px;
    }
}
.warp_popup_form .popup_logo{
    margin: 20px 0px;
}
.warp_popup_form select{
    height: 40px;
    margin: 16px auto;
    background: #f7f7f7; 
}
.warp_popup_form select.foundation_list{
    height: auto;
}
 
.warp_popup_form .caountry option{
    position: relative
}
.warp_popup_form .caountry option:before{
    position: absolute;
    display: block;
    z-index: 10;
    content: 'O';
    height: 10px;
    width: 10px;
    background: red;
}
.warp_popup_form .email{
    height: 40px;
    margin: 16px auto;
    background: #f7f7f7;
}
.warp_popup_form .warp_checkbox{
    text-align: left;
}
.warp_popup_form .warp_checkbox label{
    padding-left: 28px;
}
.warp_popup_form .warp_checkbox input{
    width: 20px;
    height: 20px;
    margin-left: 0;
    background: #f7f7f7;
}
.warp_popup_form .popup_btn{
    background: #033b86;
    background: linear-gradient(to top, #003276 0%, #02459e 100%);
}
.modal-body-2 .popup_btn{
    margin: 0 60px;
    background: #033b86;
    background: linear-gradient(to top, #003276 0%, #02459e 100%);
}
.modal-body-2-content{
    padding: 20px 40px 20px;
}

.warp_map_text{
    border-left: 1px solid #dbdbdb;
    padding: 0px 30px;
    width: 620px;
    width: calc(100% - 400px);
}
.warp_map_text img{
    display: block;
    margin: 0px auto;
}

.warp_funds_not_found{
    padding: 30px 20px;
}
.warp_popup_form .popup_logo{
    @include respond-to('medium') {
		max-width: 250px;
	}
}
.warp_popup_form select{
    @include respond-to('medium') {
        max-width: 255px;
        float: left;
    } 
    
}